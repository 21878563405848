import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout/';
import Head from '../components/head/head';

const BlogPost=({ data }) =>{
  const post = data.markdownRemark

  return (
    <Layout>
    <Head imageUrl={post.frontmatter.image.childImageSharp.resize.src} pageTitle={post.frontmatter.title}/>
    <div className="container">
    <article className="post-page">
      <div className="post-page__headline">
      <h1>{post.frontmatter.title}</h1>
      </div>
      <div className="article" dangerouslySetInnerHTML={{ __html: post.html }} />
    </article>
    </div>
    </Layout>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        date(formatString:"YYYY-MM-DD")
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
export default BlogPost;